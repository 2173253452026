/* You can add global styles to this file, and also import other style files */

@import "./config.scss";
html, body { height: 100%; }
body {
    margin: 0;
    color: $color_dark_grey;
}
app-loading{
    display: block;
    z-index: 10;
    width: 100%;
    position: absolute;
    top: 0;
}
.color-turqoise {
    color: $color_turqoise;
}

.color-red {
    color: $color_red;
}

.color-gold {
    color: $color_gold;
}

.color-blue {
    color: $color_blue;
}

.color-light-grey {
    color: $color_light_grey;
}

.color-dark-grey {
    color: $color_dark_grey;
}

.height-100vh {
    height: 100vh;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background: rgb(116, 209, 210);
    background: -moz-linear-gradient(180deg, rgba(116, 209, 210, 1) 0%, rgba(105, 189, 190, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(116, 209, 210, 1) 0%, rgba(105, 189, 190, 1) 100%);
    background: linear-gradient(180deg, rgba(116, 209, 210, 1) 0%, rgba(105, 189, 190, 1) 100%);
}

.page-heading {
    height: 70px;
}
.invalid-feedback {
    position: absolute;
    bottom: -20px;
}

.invalid-feedback-custom{
    display: block;
    position: absolute;
    bottom: -20px;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}

p-calendar.is-invalid.ng-invalid > .p-calendar > .p-inputtext {
    border-color: #e24c4c;
}

p-calendar.is-valid.ng-valid > .p-calendar > .p-inputtext {
    border-color: #198754;
}

p-dropdown.is-invalid.ng-invalid > .p-dropdown {
    border-color: #e24c4c;
}

p-dropdown.is-valid.ng-valid > .p-dropdown {
    border-color: #198754;
}

p-selectbutton.is-invalid.ng-invalid > .p-selectbutton .p-button {
    border-color: #e24c4c;
}

p-selectbutton.is-valid.ng-valid > .p-selectbutton .p-button {
    border-color: #198754;
}


.p-selectbutton .p-button {
    padding: 0.375rem 0.75rem;
    font-weight: bold;
}

.p-selectbutton .p-button.p-highlight,
.p-selectbutton .p-button.p-highlight:hover {
    border-color: #74d1d2;
    background: rgb(116, 209, 210);
    background: -moz-linear-gradient(180deg, rgba(116, 209, 210, 1) 0%, rgba(105, 189, 190, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(116, 209, 210, 1) 0%, rgba(105, 189, 190, 1) 100%);
    background: linear-gradient(180deg, rgba(116, 209, 210, 1) 0%, rgba(105, 189, 190, 1) 100%);
}

.p-calendar .p-button {
    border-color: #74d1d2;
    background: rgb(116, 209, 210);
    background: -moz-linear-gradient(180deg, rgba(116, 209, 210, 1) 0%, rgba(105, 189, 190, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(116, 209, 210, 1) 0%, rgba(105, 189, 190, 1) 100%);
    background: linear-gradient(180deg, rgba(116, 209, 210, 1) 0%, rgba(105, 189, 190, 1) 100%);
}

.p-inputtext {
    padding: 0.375rem 0.75rem;
}

.p-button.p-button-icon-only {
    padding: 0.375rem 0;
}

.form-label {
    font-weight: 700;
}

.link-dark {
    font-weight: 600;
}

.fixed-top{
    z-index: 999;
}

.btn-small-link{
    padding: 0.25em; font-size: 85%;
}

@media (max-width: 575.98px) {
    .mobile-compatible{
        width: 100%;
    }
    .mobile-compatible .p-selectbutton.p-buttonset  .p-button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: calc(100vw - 2rem);
        margin-bottom: 0.5rem;
    }
    .mobile-compatible .p-selectbutton.p-buttonset .p-button:first-of-type {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-right: 1px solid #ced4da;
    }
    .mobile-compatible .p-selectbutton.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
        border-radius: 6px;
        border-right: 1px solid #ced4da;
    }
    .mobile-compatible p-selectbutton.p-buttonset .p-button:not(:last-child) {
        border-right: 1px solid #ced4da;
    }
    .mobile-compatible .p-selectbutton.p-buttonset .p-button:last-of-type {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-right: 1px solid #ced4da;
    }
}
