@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?gbvis4');
  src:  url('fonts/icomoon.eot?gbvis4#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?gbvis4') format('truetype'),
    url('fonts/icomoon.woff?gbvis4') format('woff'),
    url('fonts/icomoon.svg?gbvis4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-report:before {
  content: "\e90d";
}
.icon-logout:before {
  content: "\e900";
}
.icon-note-to-doctor:before {
  content: "\e901";
}
.icon-dashboard:before {
  content: "\e902";
}
.icon-appointment:before {
  content: "\e903";
}
.icon-medicine:before {
  content: "\e904";
}
.icon-billing:before {
  content: "\e905";
}
.icon-inquiry:before {
  content: "\e906";
}
.icon-user:before {
  content: "\e907";
}
.icon-chevron-down:before {
  content: "\e908";
}
.icon-settings:before {
  content: "\e909";
}
.icon-caretaker:before {
  content: "\e90a";
}
.icon-patient:before {
  content: "\e90b";
}
.icon-facility:before {
  content: "\e90c";
}
